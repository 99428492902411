import { useAuth0 } from '@auth0/auth0-react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LabelIcon from '@mui/icons-material/Label';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { find, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import HiroApi from '../../HiroApi';
import { setCampaigns } from '../../state/campaignSlice';
import { selectCurrentFacility, selectProviders, selectQueue } from '../../state/systemSlice';
import { selectUserType } from '../../state/userSlice';
import { USER_ROLES } from '../../utils/constants/system';
import { getSpecialtyInfo, mapConsentStatus, mapReferralStatus } from '../../utils/helpers';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function CollapsibleRow(props) {
  const dispatch = useDispatch();
  const facility = useSelector(selectCurrentFacility);
  const providers = useSelector(selectProviders);
  const userType = useSelector(selectUserType);
  const queue = useSelector(selectQueue);
  const { getAccessTokenSilently } = useAuth0();
  const { index, campaign, campaigns } = props;
  const [open, setOpen] = useState(false);
  const [initialNote, setInitialNote] = useState(campaign.notes);

  const updateNote = (event) => {
    const rows = campaigns.slice();

    rows[index] = {
      ...rows[index],
      notes: event.target.value,
    };

    dispatch(setCampaigns(rows));
  };

  const saveEdit = async () => {
    const token = await getAccessTokenSilently();
    const { id, notes } = campaigns[index];

    try {
      await HiroApi.updatePatient(id, { notes }, token);

      setOpen(false);
      setInitialNote(notes);
    } catch (error) {
      console.log(error);
    }
  };

  const cancelEdit = () => {
    const rows = campaigns.slice();

    rows[index] = {
      ...rows[index],
      notes: initialNote,
    };

    dispatch(setCampaigns(rows));
    setOpen(false);
  };

  const getProviderName = (providerId) => {
    const provider = find(providers, ['id', providerId]);

    if (isEmpty(provider)) {
      return '';
    }
    return provider.displayName;
  };

  const getPatientDisplayName = (lastName, firstName) => {
    return firstName ? `${lastName}, ${firstName}` : lastName;
  };

  const formatDateTime = (timestamp, hasTime = true) => {
    if (isEmpty(timestamp)) {
      return '';
    }
    const date = dayjs(timestamp).tz(facility.timezone);
    // const hasTime = date.format('HH:mm:ss') !== '00:00:00';
    return date.format(hasTime ? 'M/D/YY h:mm A' : 'M/D/YY');
  };

  const formatDischargeDate = (timestamp) => {
    if (isEmpty(timestamp)) {
      return '';
    }
    // const date = dayjs(timestamp).tz(facility.timezone);
    const date = dayjs(timestamp).utc();
    // const hasTime = date.format('HH:mm:ss') !== '00:00:00';
    return date.format('M/D/YY');
  };

  const formatReferrals = (referrals) => {
    return (
      <div className="referrals">
        {referrals.map((referral) => (
          <p>
            {getSpecialtyInfo(referral.followupSpecialtyId)?.code}: {mapReferralStatus(referral.status)}
          </p>
        ))}
      </div>
    );
  };

  const updatePatientTag = async () => {
    try {
      const token = await getAccessTokenSilently();
      const tag = isEmpty(campaign.tag) ? 'saved' : '';
      const rows = [...campaigns];

      rows[index] = {
        ...rows[index],
        tag,
      };

      await HiroApi.updatePatient(campaign.patientId, { tag }, token);
      dispatch(setCampaigns(rows));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment key={campaign.id}>
      <TableRow>
        <TableCell className={`tag ${campaign.tag}`}>
          {!isEmpty(campaign.tag) ? (
            <LabelIcon onClick={updatePatientTag} data-id={campaign.id} />
          ) : (
            <LabelOutlinedIcon onClick={updatePatientTag} data-id={campaign.id} />
          )}
        </TableCell>
        <TableCell align="center" className={campaign.tag}>
          <Link to={`/campaigns/${campaign.id}`}>{getPatientDisplayName(campaign.lastName, campaign.firstName)}</Link>
        </TableCell>
        <TableCell align="center" className={campaign.tag}>
          {formatDateTime(campaign.dischargeDate, false)}
        </TableCell>
        <TableCell align="center" className={campaign.tag}>
          {formatDateTime(campaign?.createdAt)}
        </TableCell>
        {queue === 'ReadyForCall' ? (
          <TableCell align="center" className={campaign.tag}>
            {formatDateTime(campaign?.firstContactAttempt?.timestamp)}
          </TableCell>
        ) : null}
        {queue === 'ReadyForCall' ? (
          <TableCell align="center" className={campaign.tag}>
            {formatDateTime(campaign?.lastContactAttempt?.timestamp)}
          </TableCell>
        ) : null}
        <TableCell align="center" className={campaign.tag}>
          {getProviderName(campaign.pcpId)}
        </TableCell>
        <TableCell align="center" className={campaign.tag}>
          {formatReferrals(campaign.referrals)}
        </TableCell>
        {userType === USER_ROLES.ADMIN ? (
          <TableCell align="center" className={campaign.tag}>
            {mapConsentStatus(campaign.consent)}
          </TableCell>
        ) : null}
        <TableCell align="center" className={campaign.tag}>
          {campaign.needsAction ? <ErrorOutlineIcon className="red" /> : <ErrorOutlineIcon className="gray" />}
        </TableCell>
        <TableCell align="center" className={campaign.tag}>
          <Tooltip
            title={
              <div
                style={{
                  whiteSpace: 'pre-line',
                  fontSize: '14px',
                }}
              >
                {campaign.notes}
              </div>
            }
            placement="bottom"
            arrow
          >
            <TextSnippetOutlinedIcon
              sx={{ color: campaign.notes?.length ? 'black' : 'gray' }}
              onClick={() => setOpen(!open)}
            />
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow className="collapse">
        <TableCell colSpan={8}>
          <Collapse in={open}>
            <Box sx={{ margin: 1 }}>
              <TextField multiline rows={3} value={campaign.notes || ''} onChange={updateNote} />
              <div className="actions">
                <CheckIcon className="check" onClick={saveEdit} />
                <CloseIcon className="red" onClick={cancelEdit} />
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
