import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialState = {
  history: [],
  users: [],
  action: 'All',
  category: 'All',
  startDate: dayjs().subtract(3, 'days').format('MM/DD/YYYY'),
  endDate: dayjs().format('MM/DD/YYYY'),
};

export const auditHistoryFiltersSlice = createSlice({
  name: 'auditHistoryFilters',
  initialState,
  reducers: {
    setHistory: (state, action) => {
      state.history = action.payload;
    },
    setAuditUsers: (state, action) => {
      state.users = action.payload;
    },
    setAction: (state, action) => {
      state.action = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setPatientName: (state, action) => {
      state.patientName = action.payload;
    },
    setSpecialty: (state, action) => {
      state.specialty = action.payload;
    },
    resetAuditHistoryFilters: (state) => {
      state.history = [];
      state.users = [];
      state.action = '';
      state.category = '';
      state.startDate = dayjs().subtract(3, 'days').format('MM/DD/YYYY');
      state.endDate = dayjs().format('MM/DD/YYYY');
    },
  },
});

export const {
  setHistory,
  setAuditUsers,
  setAction,
  setCategory,
  setStartDate,
  setEndDate,
  setPatientName,
  setSpecialty,
  resetAuditHistoryFilters,
} = auditHistoryFiltersSlice.actions;

export const selectAllAuditFilters = (state) => state.auditHistory;
export const selectAuditHistory = (state) => state.auditHistory.history;
export const selectAuditUsers = (state) => state.auditHistory.users;
export const selectAuditAction = (state) => state.auditHistory.action;
export const selectAuditCategory = (state) => state.auditHistory.category;
export const selectAuditStartDate = (state) => state.auditHistory.startDate;
export const selectAuditEndDate = (state) => state.auditHistory.endDate;

export default auditHistoryFiltersSlice.reducer;
