import './style.scss';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectSpecialties, selectSpecialty, setSpecialty } from '../../state/systemSlice';

export default function SpecialtyFilter() {
  const dispatch = useDispatch();
  const specialties = useSelector(selectSpecialties);
  const selectedSpecialty = useSelector(selectSpecialty);

  const handleClick = (specialtyId) => {
    if (specialtyId === selectedSpecialty) {
      dispatch(setSpecialty(''));
    } else {
      dispatch(setSpecialty(specialtyId));
    }
  };

  return (
    <FormControl size="small" className="table-filter specialty-filter">
      <InputLabel>Specialty</InputLabel>
      <Select value={selectedSpecialty} input={<OutlinedInput label="Specialty" />}>
        {specialties.map((specialty) => (
          <MenuItem value={specialty.id} onClick={() => handleClick(specialty.id)}>
            {specialty.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
