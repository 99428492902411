import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import { selectCurrentFacility } from '../../state/systemSlice';
import { selectUserType } from '../../state/userSlice';
import { CONVERSATION_ACTOR, MESSAGE_STATUS, USER_ROLES } from '../../utils/constants/system';
import { translate } from '../../utils/helpers-api';
import FunctionMessage from './FunctionMessage';
import RecommendedMessage from './RecommendedMessage';

const timezone = require('dayjs/plugin/timezone');
const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);
dayjs.extend(timezone);

export default function Message(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { actor, body, createdAt, deliveryStatus, id, functionMessages, recommendedMessages } = props.message;
  const { campaign, debugMode, isLastPatientMessage } = props;
  const facility = useSelector(selectCurrentFacility);
  const userType = useSelector(selectUserType);
  const [loading, setLoading] = useState(false);
  const [translatedMessage, setTranslatedMessage] = useState({});

  const mapConversationActor = (actor) => {
    switch (actor) {
      case CONVERSATION_ACTOR.AI:
        return 'Chat Agent';
      case CONVERSATION_ACTOR.PATIENT:
        return 'Patient';
      case CONVERSATION_ACTOR.COORDINATOR:
        return 'Coordinator';
      default:
        return '';
    }
  };

  const mapMessageStatus = (status) => {
    switch (status) {
      case MESSAGE_STATUS.SENT:
        return <CheckCircleOutlinedIcon />;
      case MESSAGE_STATUS.DELIVERED:
        return <CheckCircleOutlinedIcon className="green" />;
      case MESSAGE_STATUS.READ:
        return <CheckCircleIcon className="green" />;
      case MESSAGE_STATUS.UNDELIVERED:
        return <ErrorOutlineIcon className="orange" />;
      case MESSAGE_STATUS.FAILED:
        return <ErrorOutlineIcon className="red" />;
      default:
        return '';
    }
  };

  const convertTime = (timestamp) => {
    return dayjs(timestamp).tz(facility.timezone).format('M/D/YY - h:mma');
  };

  const translateMessage = async (messageId) => {
    setLoading(true);

    const token = await getAccessTokenSilently();
    const translation = await translate({ messageId }, token);

    setTranslatedMessage(translation);
    setLoading(false);
  };

  return (
    <div className={`message ${actor}`}>
      <div className="message-header">
        <div className={`actor ${actor}`}>{mapConversationActor(actor)}</div>
        <div className="time">{convertTime(createdAt)}</div>
      </div>

      <div className="body">
        <div className="msg">
          <div className="text">{body}</div>
          <div className="status">{mapMessageStatus(deliveryStatus)}</div>
        </div>
        {!isEmpty(translatedMessage) ? <div className="text translation">{translatedMessage?.body}</div> : null}
        <div className="actions">
          {actor === CONVERSATION_ACTOR.PATIENT ? (
            <Chip variant="outlined" label="Translate" onClick={() => translateMessage(id)} />
          ) : null}
          {loading ? <Loader size={20} /> : null}
        </div>
      </div>

      {actor === CONVERSATION_ACTOR.PATIENT &&
      userType === USER_ROLES.ADMIN &&
      (debugMode || (isLastPatientMessage && !campaign.autopilot)) ? (
        <div className="meta-info">
          {debugMode ? <FunctionMessage functionMessages={functionMessages} /> : null}
          {!campaign.autopilot ? (
            <RecommendedMessage campaignId={campaign.id} messageId={id} recommendedMessages={recommendedMessages} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
