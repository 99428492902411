import { configureStore } from '@reduxjs/toolkit';

import auditHistorySlice from './auditHistorySlice';
import campaignSlice from './campaignSlice';
import systemSlice from './systemSlice';
import userSlice from './userSlice';

const store = configureStore({
  reducer: {
    auditHistory: auditHistorySlice,
    campaign: campaignSlice,
    system: systemSlice,
    user: userSlice,
  },
});

export default store;
